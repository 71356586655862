import FinanceApi from '@/services/FinanceApi';

const getAllOptions = async () => {
    return FinanceApi.get('/payment/types/options');
}

const getAll = async (config) => {
    return FinanceApi.get('/payment/types', config);
}

const get = async (code) => {
    return FinanceApi.get('/payment/types/' + code);
}

const store = async (data) => {
    if (data.id) {
        return FinanceApi.put('/payment/types/' + data.id, data);
    }
    return FinanceApi.post('/payment/types', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/payment/types/' + id, formData);
}

const deleteType = async (id) => {
    return FinanceApi.delete('/payment/types/' + id);
}

export default {
    getAllOptions,
    getAll,
    get,
    store,
    update,
    deleteType
}



